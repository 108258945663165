import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../Loader'
import "./footer.css"
import img1 from '../../asets/2.png'
import pdiImg from '../../asets/pdi Img.jpg'
import Axios from '../../Axios'
import Toast from '../../Tost'
import CaroselBanner from '../../components/CaroselBanner'
 const arr = [1, 2, 3]
function PreDelivery() {
  const [isLoading, setIsLoading] = useState(false)
  const [preDelivery, setPreDelivery] = useState([])
  const [pdiBanner, setPdiBanner] = useState([])



  const getPreDelivery = async () =>{
    try {
      const response = await Axios.get('/inspections'); 
         if(response.status===200){
          const data = response?.data;
                  setPreDelivery(data?.inspections)
         }
     
  } catch (err) {
      const error = err?.response?.data
       Toast(error.message)
  }finally{
      setIsLoading(false)
  }
   }

   const getPdiBanner= async () =>{
    try {
      const response = await Axios.get('/get_pdi_banner'); 
         if(response.status===200){
          const data = response?.data;
                  setPdiBanner(data?.banners)
         }
     
  } catch (err) {
      const error = err?.response?.data
       Toast(error.message)
  }finally{
      setIsLoading(false)
  }
   }

   useEffect(()=>{
     getPreDelivery()
     getPdiBanner()
   },[])

  return (
    <>
       {isLoading && <Loader/>}
    <div className="container-fluid nav-margin p-0">
        
                 <div className='w-100 overflow-hidden position-relative' style={{maxHeight:'400PX'}}>
                 <div className='banner-overlay'></div>
                    <CaroselBanner banners={pdiBanner}/>
                     <div className='position-absolute banner-caption'>
                     <h3 style={{color:'white'}} className=' text-start heading'>Earn Big <br /> On Your <br /> Investment</h3>
                      <div className='mt-md-4 my-4 '>
                      <Link to="/services/16"  className='white-btn'>Our Services</Link>
                      </div>
                     </div>
                 </div>
    </div>

    <div className="container-fluid my-5">
    <div className="container py-4">       
          <div className="row card-shadow g-3 g-md-5 py-1 rounded">
              <h3 className='subheading text-center'>Pre Delivery Inspection</h3>
          {preDelivery.map((val)=>(
            <div key={val.id} className="col-12 col-md-4 d-flex justify-content-center">
        <div className='pdi-card border p-0 mb-2 mb-md-1 rounded'> 
                <div className=' overflow-hidden p-0 rounded' style={{maxHeight:'148px'}}>
              <img src={val?.image} alt="" style={{width:'100%', height:'auto'}} className='img-fluid'/>
              </div>
               <p className='mt-1 p-2 normal-text text-black1'>{val?.content}</p> 
        </div>
        
        </div>
          ))}
          <div className="col-12 d-flex flex-column align-items-center">
          <div className='pdi-box  p-2 rounded'>
               <div className='d-flex justify-content-between align-items-center px-2 mb-2 border rounded'>
               <div style={{maxWidth:'140px'}} className='p-2'>
             <img src={pdiImg} className='img-fluid' alt='' />
             </div> 
             <div>
             <p className='px-2'>PDI Services</p>
              <p className='fw-bold px-2'>Rs. 13000</p>
             </div>           
            
               </div>

          
          
           <div className='d-flex justify-content-between align-items-center rounded border'>
           <div style={{maxWidth:'140PX'}}>
           <img src={pdiImg} alt="" className='img-fluid' />
           </div>
           <div>
           <p className='px-2'>PDI Kit</p>
           <p className='fw-bold px-2'>Rs. 19000</p>
           </div>
            
           </div>
           
          </div>
        </div>
      </div>  

          <div className='text-center mt-3'>
            {/* <button className='red-btn'> Book Now</button> */}
          </div>
    </div>
   </div>

    </>
  )
}

export default PreDelivery