
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CartContext } from '../context/CartProvider'

function FloatingCart() {
   const {pathname} = useLocation()
  const {cart } = useContext(CartContext)
const [showing, setShowing] = useState(false)
  useEffect(()=>{
    if(pathname==="/services/16" ||pathname==="/services/15" || pathname==="/services/14" || pathname==="/services/13"){
       setShowing(true)
    }else{
      setShowing(false)
    }
  },[pathname])
  return (
       <>
       {showing?(<>
        {cart.length?(<>
          <Link to="/cart" className="cart-icon">
    <div className="floating-cart">

            <i className="fas fa-shopping-cart" style={{color:'black'}}></i>
            <span className="badgecart">{cart?.length ? cart?.length :0}</span>
       
  
</div>
 </Link>
  </>):null} 
  </>
       ):null}
  
{pathname==="/"?(<div className="floating-whatsapp">
  <a href="https://api.whatsapp.com/send?phone=918929227077" target="_blank" >
    <i className="fab fa-whatsapp"></i>
  </a>
</div>):null}

{pathname==="/"?(<div className="floating-call">
  <a href="tel:8929227077" target="_blank" >
  <i className="fa-solid fa-phone" style={{color: 'white'}}></i>
  </a>
</div>):null}
       </>
  )
}

export default FloatingCart