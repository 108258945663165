import React from 'react'
import Navbar1 from './Navbar'
import NavbarMobile from './NavbarMobile'

function HandleNavbar() {
  return (
    <>
      <div className='d-none d-md-block'>
           <Navbar1 static="static"/>
      </div>
      <div className='d-block d-md-none'>
          <NavbarMobile static="static"/>
      </div>
      </>
  )
}

export default HandleNavbar