import React, { useContext, useEffect, useState } from 'react'
import UserAddressForm from '../../../components/UserAddressForm'
import { UserAddressContext } from '../../../context/UserAddressProvider'
import Loader from '../../../Loader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Address from '../../../asets/address.jpg'
import Autocomplete from "react-google-autocomplete";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { AuthContext } from '../../../AuthProvider'
import { Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GeoLocation from '../../added-cart/GeoLocation';
//import LiveLocationMap from './GeoAddressWithMap';


//import Map from './Map';
function UserAddress() {
   const {loading, userAddress, handleGetAllAddress, handleDeleteAddress, onSelectAddress} = useContext(UserAddressContext)
   const {userToken} = useContext(AuthContext)
    const navigate=useNavigate()
     const [latitude, setLatitude] = useState("")
      const [longitude, setLongitude] = useState("")
      const [address, setAddress] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
     //google lat long
    useEffect(() => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
    
            fetchAddress(position.coords.latitude, position.coords.longitude);
          });
        } else {
          console.error('Geolocation is not available in this browser.');
        }
      }, []);
      const fetchAddress = async (lat, lon) => {
         const apiKey = 'AIzaSyCYgRsRDd5E0JnBHCQ9N6NZuIULVld2O1w';
         const reverseGeocodingAPI = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;
        
         
         try {
           const response = await axios.get(reverseGeocodingAPI);
           console.log("add",response.data);
           if (response.data && response.data.results && response.data.results.length > 0) {
             setAddress(response.data.results[0].formatted_address);
           } else {
             console.error('No address found.');
           }
         } catch (error) {
           console.error('Error fetching address:', error);
         } finally {
            setIsLoading(false);
         }
       };
     
   useEffect(()=>{
     if(userToken){
        handleGetAllAddress(userToken)
     }

   },[userToken])

   const onChooseAddress = (id)=>{
    onSelectAddress(id, userToken)
    handleGetAllAddress(userToken)
   }
   const backServices = ()=>{
         navigate("/services/16")
   }
   const backCart = ()=>{
    navigate("/cart")
}
   
   return (
    <>
    {(isLoading || loading) && <Loader/>}
     <div className="container-fluid nav-margin fullHeight">
        <div className="container">
        {/* <GeoLocation/> */}
            <div className="row">
             <div className="col-12 py-3 d-flex justify-content-between align-items-center">
                <IconButton onClick={backCart} aria-label="arrow" color='error' style={{fontSize:'9px'}}>
             <ArrowBackIcon /> <span className="" style={{fontSize:'12px', color:'#d32f2f'}}>Checkout</span>
              </IconButton>
              <h4 className='text-center red-t py-2'>Your Address</h4>           
              <IconButton onClick={backServices} aria-label="arrow" color='error' style={{fontSize:'9px', visibility:'hidden'}}>
              <span className="" style={{fontSize:'12px', color:'#d32f2f'}}>Services</span><ArrowForwardIcon />
                </IconButton>
             </div>

          {
            userAddress?.length?(<>
              {
                userAddress?.map((val)=>(
                    
                    <div key={val.id} className="col-12">
                    <div style={{position:'relative'}} className={`address-container mb-3 py-0 p-md-3 card-shadow bg-lightblue rounded d-flex flex-column flex-md-row align-items-center justify-content-between ${val.is_default ? 'selected' : ''}`}>
                    {val.is_default ? (<>
        <IconButton aria-label="select" className='checked-box' sx={{position:'absolute',fontSize:'22px', color:'#DE3820'}}>
  <CheckCircleIcon />
</IconButton>
        {/* <i class="fa-regular fa-circle-check fa-sm checked-box" style={{position:'absolute',fontSize:'22px', color:'#DE3820'}}></i> */}
       </>):(
        <>
        <IconButton aria-label="select" onClick={()=>{onChooseAddress(val?.id)}} className='checked-box' sx={{position:'absolute',fontSize:'22px', color:'#DE3820'}}>
  <RadioButtonUncheckedIcon />
</IconButton>
        </>
       )} 
                       
                       
                        <div className='pt-1 pt-md-0'>
                           
                            <p className='fw-bold mb-0'>House No. / Flat NO.: {val?.house_no}, {val?.locality}, {val?.landmark}</p>
                            <p className='fw-bold mb-0'>Pin Code: {val?.pin_code}</p>
                        </div>
                        <div className=' w-50 d-flex flex-md-column flex-row justify-content-between align-items-end'>
                        <Button variant="outlined" className='d-none d-md-flex' color="error" sx={{marginBottom:'4px',width:'110px'}} onClick={()=>handleDeleteAddress(val?.id, userToken)} startIcon={<DeleteIcon />}>
                            Delete
                            </Button>
                            <IconButton aria-label="delete" onClick={()=>handleDeleteAddress(val?.id, userToken)} className='d-md-none d-block'>
                            <DeleteIcon />
                            </IconButton>                          
                            <UserAddressForm oldAddress={val} isLoading={loading} Show="Show" lat={latitude} long={longitude} liveaddress={address}/>
                        </div>
                        <div>
 
                        </div>
                    </div>
                </div>
                ))
             }
            </>):(<>
              <div className='row'>
        <div className="col-12 col-md-3"></div>
        <div className="col-12 col-md-6">
          <div className='d-flex justify-content-center align-items-center' style={{maxHeight:'200px', width:'100%', height:'200px'}}>           
          <img src={Address} className='' style={{width:'200px', height:'auto', }} alt="" />
          </div>
        <div>
        <div className="col-12 col-md-3"></div>
         
         </div>
        </div>
     
      </div>
            </>)
          }

           
              
                <div className="col-12 py-3 text-center">
                
                
                   {loading?<Loader/>:<UserAddressForm isLoading={loading} addressDetails={userAddress} lat={latitude} long={longitude} liveaddress={address}/>} 
                    
              

                </div>
                <div className="col-12">
      
             
                
                  
                </div>
            </div>
        </div>
     </div>
     </>
  )
}

export default UserAddress