import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import brandlogo from '../asets/logo.png'
import Login from '../page/auth-pages/Login'
import { AuthContext } from '../AuthProvider'
import { CartContext } from '../context/CartProvider'
import Axios from '../Axios'
import Toast from '../Tost'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'

function NavbarMobile() {
    const {cart ,setCart} = useContext(CartContext)
    const {userToken, setUserToken} = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    const [path, setPath] = useState(false)
   const [useOverlay,  setUseOverlay] = useState(false)
  const [brandLogo, setBrandLogo] = useState("")

   const getBrandLogo = async ()=>{
    setIsLoading(true)
    try{
         const response = await Axios.get('/get-logo')
         if(response.status===200){
            const data = response?.data;
             setBrandLogo(data?.logo)
             console.log("logo",data);
           }
    } catch (err) {
        const error = err.response.data
     
        
    }finally{
        setIsLoading(false)
    }
}

useEffect(()=>{
  getBrandLogo()
},[])
    const onLogout = async ()=>{
      setIsLoading(true)
      try{
           const response = await Axios.get('/logout', {
              headers:{
                  Authorization: `Bearer ${userToken}`
              }
           })
           if(response.status===200){
              const data = response?.data;
               if(data){
                  localStorage.removeItem('userToken');
                  setUserToken("")
                  setCart(0)
                  localStorage.removeItem("cart")
               } 
              Toast(data?.message,response.status)
              navigate("/") 
             }
      } catch (err) {
          const error = err.response.data
          Toast(error.message)
          
      }finally{
          setIsLoading(false)
      }
  }
      
         useEffect(()=>{
            if(location.pathname==="/services" || location.pathname==="/services/16" || location.pathname==="/services" || location.pathname === "/services/15" || location.pathname=== "/services/14" || location.pathname==="/services/13" ){
                   setPath(true)
            }else{
             setPath(false)
            }
         },[location.pathname])

         const onCartClick = ()=>{
          if(userToken){
              if(cart.length){
                  navigate("/cart")
              }else{
               Toast("Please add items into your cart", 200)
              }
          }else{
           Toast("Kindly Login", 200)
          }
     }
 const onOverlay = ()=>{
        setUseOverlay(!useOverlay)
 }



  return (
    <>
    
    <nav className="navbar bg-body-tertiary fixed-top" style={{backgroundColor:'white'}}>
    
    <div className="container-fluid">
    <NavLink className="navbar-brand ms-md-3" to="/">
      <img src={brandLogo?brandLogo:brandlogo} style={{maxWidth:'180px'}} alt="Bootstrap" className='img-fluid' />
    </NavLink>
      <button className="navbar-toggler"  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="offcanvas offcanvas-start" style={{minWidth:'50vw'}} tabindex="-1" data-bs-backdrop="false" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
          <NavLink className="navbar-brand ms-md-3" id="offcanvasNavbarLabel" to="/">
      <img src={brandLogo?brandLogo:brandlogo} alt="Bootstrap" style={{maxWidth:'180px'}} className='img-fluid' />
    </NavLink>
          <button type="button"  className="btn-close" onClick={onOverlay} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body" style={{backgroundColor:'white', zIndex:'99999'}}>
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <NavLink  className="nav-link nav-text" to="/" >Home</NavLink>
            </li>
            <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <NavLink  className="nav-link nav-text" to="/services/14">Services</NavLink>
            </li>
           
            {
                userToken?(<>
                    
                    <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href='/'  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Profile
              </a>
              <ul className="dropdown-menu px-2">
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/my-details"><span>My Details</span> <i class="fa-solid fa-caret-right"></i></NavLink></li>
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/my-orders"><span>Order Status</span> <i class="fa-solid fa-caret-right"></i></NavLink></li>
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/my-cars"><span>My Cars</span><i class="fa-solid fa-caret-right"></i></NavLink> </li>
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/maintenance"><span>Maintenance</span> <i class="fa-solid fa-caret-right"></i></NavLink></li>
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/my-coupons"><span>My Coupons</span> <i class="fa-solid fa-caret-right"></i></NavLink></li>
                <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><NavLink className="nav-text d-flex justify-content-between align-items-center" style={{color:'black'}} to="/my-orders"><span>My Orders</span> <i class="fa-solid fa-caret-right"></i></NavLink></li>
              </ul>
            </li>
           {userToken && <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <a className=" nav-text position-relative" onClick={onCartClick}>
            <div class="cart-icon">
            <i class="fas fa-shopping-cart"></i>
            <span class="badge">{cart.length ? cart.length :0}</span>
            </div>           
          </a>
            </li>} 
            <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <button onClick={onLogout}  className="nav-link nav-text" to="/services/16">Logout</button>
            </li>
                </>):(<>
                  
                    <li className="nav-item">
                <Login />
            </li>
            <li className="nav-item mt-2">
            <a className="nav-text px-0 px-md-1  py-1" href="tel:8929227077"  ><i class="fa-solid fa-phone-volume" style={{color:'#DE3820'}}></i> 8929227077</a>    
            </li>
                </>)   
            }
            
          </ul>
        </div>
      </div>
    </div>
  </nav>
  </>
  )
}

export default NavbarMobile